import { useState } from "react";
import { Popover } from "../../controls";
import { TransactionHistoryLink } from "../common/TransactionHistoryLink";
import {
    DealUpdateIdentifier,
    dealUpdatesIdentifiersTitles,
} from "../../../types/amr-pipeline/enums/DealUpdateIdentifier";
import { constants } from "../../../constants";
import { TransactionType } from "../../../types/amr-pipeline/enums/TransactionType";
import { TransactionStatus } from "../../../types/amr-pipeline/enums/TransactionStatus";
import { OnHoverTooltip } from "../../common";
import { dateTimeUtils } from "../../../utils";
import moment from "moment";

interface DealUpdatesPopoverProps {
    referenceName: string;
    dealReferenceName: string;
    dealLegalName: string;
    lastUpdated: Date;
    type: TransactionType;
    status: TransactionStatus;
    version?: number;
    dealUpdates?: DealUpdateIdentifier[];
    historyLinkTarget?: React.HTMLAttributeAnchorTarget;
    withOverlayPrefix?: boolean;
    disabled?: boolean;
    onOpen?: () => void;
    onVersionHistoryClick?: () => void;
}

export function DealUpdatesPopover({
    referenceName,
    dealReferenceName,
    dealLegalName,
    lastUpdated,
    type,
    status,
    version,
    dealUpdates = [],
    historyLinkTarget,
    withOverlayPrefix = false,
    disabled,
    onOpen,
    onVersionHistoryClick,
}: DealUpdatesPopoverProps) {
    const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

    if (!lastUpdated) {
        return <span>{constants.emptyPlaceholder}</span>;
    }

    const isOriginatingTransaction = type !== TransactionType.amr;
    const isDraft = status === TransactionStatus.Draft;
    const isClosed = status === TransactionStatus.Closed;
    const lastUpdatedDate = isClosed
        ? moment(dateTimeUtils.resetTimeZone(lastUpdated))
        : dateTimeUtils.changeDateTimeZone(
              lastUpdated,
              constants.estTimezone,
              false
          );

    const formattedLastUpdated = isClosed
        ? lastUpdatedDate.format(constants.dateFormat)
        : lastUpdatedDate.format(constants.dateTimeFormat) + " EST";
    const isActivePriced = [
        TransactionStatus.Active,
        TransactionStatus.Priced,
    ].includes(status);
    const showDealUpdatesIdentifiers = !!dealUpdates.length && isActivePriced;
    const tooltipOverlay = withOverlayPrefix
        ? "Last Updated: " + formattedLastUpdated
        : formattedLastUpdated;

    const PseudoLink = (
        <span className="pseudo-link">
            <OnHoverTooltip
                overlay={tooltipOverlay}
                disabled={disabled || isTooltipDisabled || !lastUpdated}
            >
                {lastUpdatedDate.format(constants.dateFormat)}
            </OnHoverTooltip>
        </span>
    );

    return !disabled ? (
        <Popover
            className="popover-last-updated"
            title="Last Updated"
            label={<span className="name-tag">{dealLegalName}</span>}
            actionButton={PseudoLink}
            onOpen={() => {
                !disabled && setIsTooltipDisabled(true);
                onOpen?.();
            }}
            onClose={() => setIsTooltipDisabled(false)}
        >
            <time className="deal-updates-date">{formattedLastUpdated}</time>
            <div className="last-updated-scroll-content">
                {showDealUpdatesIdentifiers && (
                    <ul className="list-bullet updates-list">
                        {dealUpdates.map((u) => (
                            <li key={u}>{dealUpdatesIdentifiersTitles[u]}</li>
                        ))}
                    </ul>
                )}
            </div>
            {isOriginatingTransaction && !isDraft && (
                <div className="transaction-history-link">
                    <TransactionHistoryLink
                        target={historyLinkTarget}
                        referenceName={referenceName}
                        dealReferenceName={dealReferenceName}
                        version={version}
                        onClick={onVersionHistoryClick}
                    />
                </div>
            )}
        </Popover>
    ) : (
        PseudoLink
    );
}
